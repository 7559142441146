var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
		'c-block-list-images-custom',
		'w-5col >=768:w-8col max-w-full mx-auto' ],attrs:{"id":("images-" + _vm._uid)}},[(_vm.title)?_c('LongReadTarget',{attrs:{"container-id":("images-" + _vm._uid),"title":_vm.title,"disabled":Boolean(_vm.accordionNestingLevel)}},[_c('BaseH2',{staticClass:"text-primary-button mb-sm",domProps:{"textContent":_vm._s(_vm.title)}})],1):_vm._e(),_vm._v(" "),(_vm.images && _vm.images.length)?_c('div',{class:[
			'c-block-list-images-custom__content-desktop',
			'hidden >=768:block w-full' ]},[_c('div',{ref:"desktopSlider",class:['relative w-full h-0'],style:(("padding-top:calc((" + _vm.containerAspectRatio + ") * 100%);"))},[_c('div',{class:[
					'absolute top-1/2 left-1/2',
					'transform -translate-y-1/2 -translate-x-1/2' ]},[_c('TransitionExt',{attrs:{"duration":500,"mode":"out-in","name":"block-list-images-custom__item"}},[(_vm.images[_vm.index] && _vm.renderedImages[_vm.index])?_c('div',{key:("image-" + _vm.index),staticClass:"relative",style:({
							width: ((_vm.renderedImages[_vm.index].renderedWidth) + "px"),
							height: ((_vm.renderedImages[_vm.index].renderedHeight) + "px"),
						})},[_c('UmbracoImageExt',{class:[
								'max-w-full max-h-full',
								'rounded-md overflow-hidden' ],style:({
								width: ((_vm.renderedImages[_vm.index].renderedWidth) + "px"),
								height: ((_vm.renderedImages[_vm.index].renderedHeight) + "px"),
							}),attrs:{"aria-describedby":_vm.images[_vm.index].altText
									? (_vm._uid + "-caption-" + _vm.index)
									: null,"widths":[
								320,
								480,
								864,
								1104,
								480 * 2,
								864 * 2,
								1104 * 2 ],"alt":_vm.images[_vm.index].altText,"source-url":_vm.images[_vm.index].cropUrl || _vm.images[_vm.index].url,"source-width":_vm.images[_vm.index].width,"source-height":_vm.images[_vm.index].height}}),_vm._v(" "),(_vm.images[_vm.index].imageCredits)?_c('div',{class:[
								'c-block-list-images-custom__credit',
								'absolute bottom-8 right-8 z-10 text-white text-16 rounded-full flex items-center py-4 px-16 m-0' ]},[_c('SvgCopyright',{staticClass:"w-14 h-14 mr-6"}),_vm._v(" "),_c('div',{staticClass:"w-fit whitespace-nowrap"},[_vm._v("\n\t\t\t\t\t\t\t\t"+_vm._s(_vm.images[_vm.index].imageCredits)+"\n\t\t\t\t\t\t\t")])],1):_vm._e()],1):_vm._e()])],1),_vm._v(" "),(_vm.images.length > 1)?_c('BaseButton',{class:[
					'absolute top-1/2 right-0',
					'transform translate-x-1/2 -translate-y-1/2' ],attrs:{"aria-label":"Se næste billede","aria-disabled":_vm.index === _vm.images.length - 1},on:{"click":_vm.gotoNext},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('SvgCaret')]},proxy:true}],null,false,870354816)}):_vm._e(),_vm._v(" "),(_vm.images.length > 1)?_c('BaseButton',{class:[
					'absolute top-1/2 left-0',
					'transform -translate-x-1/2 -translate-y-1/2' ],attrs:{"aria-label":"Se forrige billede","aria-disabled":_vm.index === 0},on:{"click":_vm.gotoPrevious},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('SvgCaret',{staticClass:"transform -scale-100"})]},proxy:true}],null,false,313104835)}):_vm._e()],1),_vm._v(" "),(_vm.images[_vm.index])?_c('div',{staticClass:"mt-xs flex flex-col text-center"},[(_vm.images.length > 1)?_c('span',{staticClass:"text-h7 text-primary-button font-semibold",domProps:{"textContent":_vm._s(((_vm.index + 1) + " / " + (_vm.images.length)))}}):_vm._e(),_vm._v(" "),(_vm.images[_vm.index].imageText)?_c('div',{staticClass:"text-h7 mt-8",attrs:{"id":(_vm._uid + "-caption-" + _vm.index)},domProps:{"innerHTML":_vm._s(_vm.images[_vm.index].imageText)}}):_vm._e()]):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.images && _vm.images.length)?_c('SwiperWrap',{ref:"mobileSlider",class:[
			'c-block-list-images-custom__content-mobile',
			'relative -mx-layout-margin >=768:mx-0',
			'>=768:hidden' ],attrs:{"move-by":1,"items-wrapper-class-names":[
			'flex w-full u-hide-scrollbar',
			'snap snap-x snap-mandatory',
			'pr-layout-margin >=768:pr-0',
			'>=768:px-0 >=768:space-x-0',
			'>=768:rounded-md',

			{
				'overflow-x-auto': _vm.images.length >= 2,
				'overflow-x-hidden': _vm.images.length === 1,
			} ]},scopedSlots:_vm._u([{key:"items",fn:function(){return _vm._l((_vm.images),function(image,iindex){return _c('div',{key:image.id,ref:"mobileItems",refInFor:true,class:[
					'c-block-list-images-custom__item',
					'snap-start pl-layout-margin',
					'>=768:min-w-full >=768:rounded-0 >=768:pl-0' ],style:({
					'padding-right': ((iindex === _vm.images.length - 1 ? _vm.mobileOffset : 0) + "px"),
				}),attrs:{"aria-labelledby":image.altText ? (_vm._uid + "-caption-" + (image.id)) : null}},[_c('UmbracoImageExt',{style:(("height: " + _vm.mobileHeight + "px !important")),attrs:{"widths":[
						320,
						480,
						864,
						1104,
						480 * 2,
						864 * 2,
						1104 * 2 ],"alt":image.altText,"source-url":image.cropUrl || image.url,"source-width":image.width,"source-height":image.height}})],1)})},proxy:true},{key:"before",fn:function(ref){
					var showPagination = ref.showPagination;
					var go = ref.go;
return [_c('div',{class:[
					'c-block-list-images-custom__pagination',
					'absolute top-0 z-10',
					'w-full pointer-events-none' ]},[_c('BaseButton',{class:[
						'pointer-events-auto',
						'absolute top-1/2 left-0',
						'transform -translate-y-1/2 -translate-x-1/2',
						'hidden >=768:block' ],attrs:{"aria-disabled":!showPagination.previous},on:{"click":function () { return go.previous(false); }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('SvgCaret',{staticClass:"transform -scale-100"})]},proxy:true}],null,true)}),_vm._v(" "),_c('BaseButton',{class:[
						'pointer-events-auto',
						'absolute top-1/2 left-full',
						'transform -translate-y-1/2 -translate-x-1/2',
						'hidden >=768:block' ],attrs:{"aria-disabled":!showPagination.next},on:{"click":function () { return go.next(false); }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('SvgCaret')]},proxy:true}],null,true)})],1)]}},{key:"default",fn:function(ref){
					var pagination = ref.pagination;
return [(_vm.images[pagination.index])?_c('div',{staticClass:"mt-sm ml-layout-margin >=768:ml-0"},[(_vm.images.length >= 2)?_c('div',{staticClass:"text-h5 text-primary-button font-semibold",domProps:{"textContent":_vm._s(((pagination.index + 1) + " / " + (pagination.count)))}}):_vm._e(),_vm._v(" "),(_vm.images[pagination.index].imageText)?_c('div',{staticClass:"text-h5 mt-4",attrs:{"id":(_vm._uid + "-caption-" + (_vm.images[pagination.index].id))},domProps:{"innerHTML":_vm._s(_vm.images[pagination.index].imageText)}}):_vm._e()]):_vm._e()]}}],null,false,2400430258)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
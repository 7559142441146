<template>
	<div
		:id="`images-${_uid}`"
		:class="[
			'c-block-list-images-custom',
			'w-5col >=768:w-8col max-w-full mx-auto',
		]"
	>
		<LongReadTarget
			v-if="title"
			:container-id="`images-${_uid}`"
			:title="title"
			:disabled="Boolean(accordionNestingLevel)"
		>
			<BaseH2 class="text-primary-button mb-sm" v-text="title" />
		</LongReadTarget>

		<!-- Desktop -->
		<div
			v-if="images && images.length"
			:class="[
				'c-block-list-images-custom__content-desktop',
				'hidden >=768:block w-full',
			]"
		>
			<div
				ref="desktopSlider"
				:class="['relative w-full h-0']"
				:style="`padding-top:calc((${containerAspectRatio}) * 100%);`"
			>
				<div
					:class="[
						'absolute top-1/2 left-1/2',
						'transform -translate-y-1/2 -translate-x-1/2',
					]"
				>
					<TransitionExt
						:duration="500"
						mode="out-in"
						name="block-list-images-custom__item"
					>
						<div
							v-if="images[index] && renderedImages[index]"
							:key="`image-${index}`"
							:style="{
								width: `${renderedImages[index].renderedWidth}px`,
								height: `${renderedImages[index].renderedHeight}px`,
							}"
							class="relative"
						>
							<!-- Image -->
							<UmbracoImageExt
								:aria-describedby="
									images[index].altText
										? `${_uid}-caption-${index}`
										: null
								"
								:widths="[
									320,
									480,
									864,
									1104,
									480 * 2,
									864 * 2,
									1104 * 2,
								]"
								:alt="images[index].altText"
								:source-url="
									images[index].cropUrl || images[index].url
								"
								:source-width="images[index].width"
								:source-height="images[index].height"
								:class="[
									'max-w-full max-h-full',
									'rounded-md overflow-hidden',
								]"
								:style="{
									width: `${renderedImages[index].renderedWidth}px`,
									height: `${renderedImages[index].renderedHeight}px`,
								}"
							/>
							<!-- Credit -->
							<div
								v-if="images[index].imageCredits"
								:class="[
									'c-block-list-images-custom__credit',
									'absolute bottom-8 right-8 z-10 text-white text-16 rounded-full flex items-center py-4 px-16 m-0',
								]"
							>
								<SvgCopyright class="w-14 h-14 mr-6" />
								<div class="w-fit whitespace-nowrap">
									{{ images[index].imageCredits }}
								</div>
							</div>
						</div>
					</TransitionExt>
				</div>

				<!-- Next Button -->
				<BaseButton
					v-if="images.length > 1"
					aria-label="Se næste billede"
					:aria-disabled="index === images.length - 1"
					:class="[
						'absolute top-1/2 right-0',
						'transform translate-x-1/2 -translate-y-1/2',
					]"
					@click="gotoNext"
				>
					<template #icon>
						<SvgCaret />
					</template>
				</BaseButton>

				<!-- Previous Button -->
				<BaseButton
					v-if="images.length > 1"
					aria-label="Se forrige billede"
					:aria-disabled="index === 0"
					:class="[
						'absolute top-1/2 left-0',
						'transform -translate-x-1/2 -translate-y-1/2',
					]"
					@click="gotoPrevious"
				>
					<template #icon>
						<SvgCaret class="transform -scale-100" />
					</template>
				</BaseButton>
			</div>

			<!-- Caption -->
			<div v-if="images[index]" class="mt-xs flex flex-col text-center">
				<span
					v-if="images.length > 1"
					class="text-h7 text-primary-button font-semibold"
					v-text="`${index + 1} / ${images.length}`"
				></span>

				<div
					v-if="images[index].imageText"
					:id="`${_uid}-caption-${index}`"
					class="text-h7 mt-8"
					v-html="images[index].imageText"
				></div>
			</div>
		</div>

		<!-- Mobile -->
		<SwiperWrap
			v-if="images && images.length"
			ref="mobileSlider"
			:move-by="1"
			:class="[
				'c-block-list-images-custom__content-mobile',
				'relative -mx-layout-margin >=768:mx-0',
				'>=768:hidden',
			]"
			:items-wrapper-class-names="[
				'flex w-full u-hide-scrollbar',
				'snap snap-x snap-mandatory',
				'pr-layout-margin >=768:pr-0',
				'>=768:px-0 >=768:space-x-0',
				'>=768:rounded-md',

				{
					'overflow-x-auto': images.length >= 2,
					'overflow-x-hidden': images.length === 1,
				},
			]"
		>
			<!-- Items -->
			<template #items>
				<div
					v-for="(image, iindex) in images"
					ref="mobileItems"
					:key="image.id"
					:aria-labelledby="
						image.altText ? `${_uid}-caption-${image.id}` : null
					"
					:class="[
						'c-block-list-images-custom__item',
						'snap-start pl-layout-margin',
						'>=768:min-w-full >=768:rounded-0 >=768:pl-0',
					]"
					:style="{
						'padding-right': `${
							iindex === images.length - 1 ? mobileOffset : 0
						}px`,
					}"
				>
					<UmbracoImageExt
						:widths="[
							320,
							480,
							864,
							1104,
							480 * 2,
							864 * 2,
							1104 * 2,
						]"
						:alt="image.altText"
						:source-url="image.cropUrl || image.url"
						:source-width="image.width"
						:source-height="image.height"
						:style="`height: ${mobileHeight}px !important`"
					/>
				</div>
			</template>

			<!-- Pagination -->
			<template #before="{ showPagination, go }">
				<div
					:class="[
						'c-block-list-images-custom__pagination',
						'absolute top-0 z-10',
						'w-full pointer-events-none',
					]"
				>
					<!-- Previous -->
					<BaseButton
						:aria-disabled="!showPagination.previous"
						:class="[
							'pointer-events-auto',
							'absolute top-1/2 left-0',
							'transform -translate-y-1/2 -translate-x-1/2',
							'hidden >=768:block',
						]"
						@click="() => go.previous(false)"
					>
						<template #icon>
							<SvgCaret class="transform -scale-100" />
						</template>
					</BaseButton>

					<!-- Next -->
					<BaseButton
						:aria-disabled="!showPagination.next"
						:class="[
							'pointer-events-auto',
							'absolute top-1/2 left-full',
							'transform -translate-y-1/2 -translate-x-1/2',
							'hidden >=768:block',
						]"
						@click="() => go.next(false)"
					>
						<template #icon>
							<SvgCaret />
						</template>
					</BaseButton>
				</div>
			</template>

			<!-- Image Text -->
			<template #default="{ pagination }">
				<div
					v-if="images[pagination.index]"
					class="mt-sm ml-layout-margin >=768:ml-0"
				>
					<div
						v-if="images.length >= 2"
						class="text-h5 text-primary-button font-semibold"
						v-text="`${pagination.index + 1} / ${pagination.count}`"
					></div>

					<div
						v-if="images[pagination.index].imageText"
						:id="`${_uid}-caption-${images[pagination.index].id}`"
						class="text-h5 mt-4"
						v-html="images[pagination.index].imageText"
					></div>
				</div>
			</template>
		</SwiperWrap>
	</div>
</template>

<script>
import SwiperWrap from '~/citi-baseline/components/SwiperWrap';
import SvgCaret from '~/assets/svgs/caret-icon.svg?inline';
import UmbracoImageExt from '~/components/shared/UmbracoImageExt';
import LongReadTarget from '~/citi-baseline/components/LongReadTarget';
import SvgCopyright from '~/assets/svgs/copyright-icon.svg?inline';

export default {
	name: 'BlockListImagesCustom',

	components: {
		SwiperWrap,
		SvgCaret,
		UmbracoImageExt,
		LongReadTarget,
		SvgCopyright,
	},

	inject: {
		accordionNestingLevel: {
			default: null,
		},
	},

	inheritAttrs: false,

	props: {
		title: {
			type: String,
			required: false,
		},
		images: {
			type: Array,
			required: true,
		},
	},

	data() {
		return {
			index: 0,
			mobileOffset: 0,
			observer: null,

			desktopDimensions: {
				width: 0,
				height: 0,
			},

			mobileDimensions: {
				width: 0,
				height: 0,
			},
		};
	},

	computed: {
		renderedImages() {
			const { width, height } = this.desktopDimensions;

			return this.images?.map((e) => {
				let renderedWidth = width;
				let renderedHeight = (e.height / e.width) * renderedWidth;

				if (renderedHeight > height) {
					renderedHeight = height;
					renderedWidth = (e.width / e.height) * renderedHeight;
				}

				return {
					...e,
					renderedWidth,
					renderedHeight,
				};
			});
		},
		containerAspectRatio() {
			if (
				this.images.length === 1 &&
				this.renderedImages[this.index].height /
					this.renderedImages[this.index].width <
					9 / 16
			) {
				return (
					this.renderedImages[this.index].height /
					this.renderedImages[this.index].width
				);
			}
			return 9 / 16;
		},

		mobileHeight() {
			let ratio = 0;
			let highestRatio = 0;
			this.images.forEach((e) => {
				const r = e.width / e.height;
				highestRatio = Math.max(highestRatio, r);
				ratio = highestRatio === r ? e.height / e.width : ratio;
			});

			let inset = 16;
			if (this.$refs?.mobileItems) {
				const { mobileItems } = this.$refs;
				const target = mobileItems[0] || mobileItems;
				inset = parseFloat(getComputedStyle(target).paddingLeft);
			}

			const calculatedInset = inset * (this.images.length >= 2 ? 3 : 2);
			const width = this.mobileDimensions.width - calculatedInset;
			return Math.min(width * ratio, 390);
		},
	},

	mounted() {
		this.onResize();
		window.addEventListener('resize', this.onResize);

		if (this.$refs?.desktopSlider && this.$refs?.mobileSlider) {
			this.observer = new IntersectionObserver(
				([{ isIntersecting }]) => isIntersecting && this.onResize(),
				{ threshold: 0.1 }
			);

			const { desktopSlider, mobileSlider } = this.$refs;
			desktopSlider && this.observer.observe(desktopSlider);
			mobileSlider.$el && this.observer.observe(mobileSlider.$el);
		}
	},

	beforeDestroy() {
		window.removeEventListener('resize', this.onResize);
	},

	methods: {
		gotoPrevious() {
			this.index !== 0 && this.index--;
		},
		gotoNext() {
			this.index !== this.images.length - 1 && this.index++;
		},

		onResize() {
			if (this.$refs?.desktopSlider) {
				const { desktopSlider } = this.$refs;
				const { width, height } = desktopSlider.getBoundingClientRect();
				this.desktopDimensions = { width, height };
			}

			if (this.$refs?.mobileSlider) {
				const mobileSlider = this.$refs.mobileSlider.$el;
				const { width } = mobileSlider.getBoundingClientRect();
				this.mobileDimensions = { width };

				/**
				 * This ain't optimal, but it's
				 * essentially just figuring out
				 * the right padding of the last
				 * mobile slider item.
				 */
				if (this.$refs.mobileItems) {
					const { mobileItems } = this.$refs;
					const { width: containerWidth } = this.mobileDimensions;

					const target = mobileItems[0] || mobileItems;
					const inset = parseFloat(
						getComputedStyle(target).paddingLeft
					);

					this.$nextTick(() => {
						const last = mobileItems[mobileItems.length - 1];
						const { width: itemWidth } =
							last.getBoundingClientRect();

						this.mobileOffset = containerWidth - itemWidth - inset;
					});
				}
			}
		},
	},
};
</script>

<style>
@screen <768 {
	.c-block-list-images-custom .c-umbraco-image {
		@apply rounded-md overflow-hidden;
	}
}

.c-block-list-images-custom__credit {
	background-color: rgba(0, 0, 0, 0.7) !important;
}

.c-block-list-images-custom .c-block-list-images-custom__pagination {
	height: 0;
	padding-top: calc((9 / 16) * 100%);
}

.c-block-list-images-custom__content-desktop .c-block-list-images-custom__item {
	padding-top: calc((9 / 16) * 100%);
}

/* .c-block-list-images-custom__content-mobile
	.c-block-list-images-custom__item:last-child {
	padding-right: calc(100vw - var(--theme-layout-margin) * 3);
} */

.t-block-list-images-custom__item-enter-active,
.t-block-list-images-custom__item-leave-active {
	@apply duration-500 ease-smooth-out;
}

.t-block-list-images-custom__item-enter,
.t-block-list-images-custom__item-leave-to {
	@apply opacity-0;
	transform: scale(0.98);
}
</style>

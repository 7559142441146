var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-block-list-image-gallery-custom w-5col >=768:w-8col mx-auto",attrs:{"id":("gallery-" + _vm._uid)}},[(_vm.title)?_c('LongReadTarget',{attrs:{"container-id":("gallery-" + _vm._uid),"title":_vm.title}},[_c('BaseH2',{staticClass:"text-primary-button mb-sm",domProps:{"textContent":_vm._s(_vm.title)}})],1):_vm._e(),_vm._v(" "),(_vm.previewImages && _vm.previewImages.length)?_c('div',{class:[
			'block space-y-18',
			'>=768:grid >=768:grid-cols-2',
			'>=768:space-y-0 >=768:gap-x-32' ]},_vm._l((_vm.previewImages),function(column,i){return _c('div',{key:("column-" + i),staticClass:"space-y-18 >=768:space-y-32"},_vm._l((column),function(image,j){return _c('div',{key:("image-" + j + "-" + (image.id)),class:[
					'c-block-list-image-gallery-custom__item',
					'relative group w-full' ]},[_c('button',{staticClass:"w-full h-full overflow-hidden rounded-md",attrs:{"aria-label":"Se billede i fuld-skærm"},on:{"click":function () { return _vm.openLightbox(_vm.images.indexOf(image)); }}},[_c('UmbracoImageExt',{staticClass:"w-full h-full",attrs:{"alt":image.altText,"widths":[320, 417, 768, 417 * 2, 768 * 2],"source-url":image.cropUrl || image.url,"source-width":image.width,"source-height":image.height,"image-class-names":"w-full"}}),_vm._v(" "),_c('BaseButton',{class:[
							'c-block-list-image-gallery-custom__fullscreen',
							'absolute top-8 right-8' ],attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('SvgFullscreen')]},proxy:true}],null,true)})],1),_vm._v(" "),(image.imageCredits)?_c('div',{class:[
						'c-block-list-image-gallery-custom__credit',
						'absolute bottom-14 right-8 text-white text-16 rounded-full flex items-center justify-center py-4 px-16' ]},[_c('SvgCopyright',{staticClass:"w-14 h-14 mr-6"}),_vm._v(" "),_c('div',{staticClass:"w-fit whitespace-nowrap"},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(image.imageCredits)+"\n\t\t\t\t\t")])],1):_vm._e()])}),0)}),0):_vm._e(),_vm._v(" "),(_vm.images && _vm.images.length)?_c('BaseButton',{class:[
			'c-block-list-image-gallery-custom__see-all',
			'mx-auto uppercase mt-md' ],on:{"click":function($event){return _vm.openLightbox(0)}},scopedSlots:_vm._u([{key:"default",fn:function(){return [_vm._v(_vm._s(_vm.titles.seeAllTitle))]},proxy:true},{key:"icon",fn:function(){return [_c('SvgFullscreen')]},proxy:true}],null,false,111820643)}):_vm._e(),_vm._v(" "),_c('Portal',{attrs:{"to":"overlay"}},[_c('Transition',{attrs:{"duration":2000,"name":"t-block-list-images-gallery-custom__lightbox"}},[(_vm.overlayActive)?_c('div',{class:[
					'block-list-image-gallery-custom__lightbox',
					'relative bg-white' ]},[_c('TransitionExt',{attrs:{"name":"block-list-images-gallery-custom__lightbox-item","duration":700,"mode":"out-in"}},[_c('figure',{key:("lightbox-item-" + _vm.index),ref:"lightboxContainer",class:[
							'block-list-image-gallery-custom__lightbox-item',
							'relative flex flex-shrink-0 justify-center items-center',
							'w-full h-screen py-xl px-layout-margin snap-center' ]},[_c('div',{staticClass:"relative",style:({
								width: ((_vm.renderedImages[_vm.index].renderedWidth) + "px"),
								height: ((_vm.renderedImages[_vm.index].renderedHeight) + "px"),
							})},[_c('UmbracoImageExt',{class:[
									'relative z-10',
									'overflow-hidden rounded-md' ],style:({
									width: ((_vm.renderedImages[_vm.index].renderedWidth) + "px"),
									height: ((_vm.renderedImages[_vm.index].renderedHeight) + "px"),
								}),attrs:{"aria-describedby":("lightbox-description-" + _vm.index),"alt":_vm.images[_vm.index].altText,"widths":[
									320,
									480,
									768,
									1024,
									1440,
									1920,
									1024 * 2,
									1440 * 2,
									1920 * 2 ],"source-width":_vm.images[_vm.index].width,"source-height":_vm.images[_vm.index].height,"source-url":_vm.images[_vm.index].cropUrl ||
									_vm.images[_vm.index].url}}),_vm._v(" "),(_vm.images[_vm.index].imageCredits)?_c('div',{class:[
									'c-block-list-image-gallery-custom__credit',
									'absolute bottom-8 right-8 z-10 text-white text-16 rounded-full flex items-center py-4 px-16 m-0' ]},[_c('SvgCopyright',{staticClass:"w-14 h-14 mr-6"}),_vm._v(" "),_c('div',{staticClass:"w-fit whitespace-nowrap"},[_vm._v("\n\t\t\t\t\t\t\t\t\t"+_vm._s(_vm.images[_vm.index].imageCredits)+"\n\t\t\t\t\t\t\t\t")])],1):_vm._e()],1),_vm._v(" "),_c('div',{class:[
								'block-list-image-gallery-custom__lightbox-text',
								'absolute text-center text-body h-xl w-full',
								'flex flex-col items-center justify-center' ]},[_c('div',{class:[
									'text-primary-button',
									'font-semibold' ],domProps:{"textContent":_vm._s(
									((_vm.index + 1) + " / " + (_vm.images && _vm.images.length))
								)}}),_vm._v(" "),(_vm.images[_vm.index].imageText)?_c('figcaption',{staticClass:"mt-8",attrs:{"id":("lightbox-description-" + _vm.index)},domProps:{"innerHTML":_vm._s(_vm.images[_vm.index].imageText)}}):_vm._e()])])]),_vm._v(" "),_c('BaseButton',{class:[
						'block-list-image-gallery-custom__lightbox-button',
						'absolute top-1/2 left-lg z-10',
						'transform -translate-x-1/2 -translate-y-1/2' ],attrs:{"aria-disabled":_vm.index === 0,"aria-label":"Se forrige billede"},on:{"click":_vm.gotoPrevious},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('SvgCaret',{staticClass:"transform -scale-x-100"})]},proxy:true}],null,false,1014155734)}),_vm._v(" "),_c('BaseButton',{class:[
						'block-list-image-gallery-custom__lightbox-button',
						'absolute top-1/2 right-lg z-10',
						'transform translate-x-1/2 -translate-y-1/2' ],attrs:{"aria-disabled":_vm.images ? _vm.index === _vm.images.length - 1 : true,"aria-label":"Se næste billede"},on:{"click":_vm.gotoNext},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('SvgCaret')]},proxy:true}],null,false,870354816)}),_vm._v(" "),_c('button',{class:[
						'absolute top-md right-md',
						'transform -translate-y-1/2 translate-x-1/2',
						'duration-200 ease-wipe-out',
						'opacity-70 hover:opacity-100' ],attrs:{"aria-label":"Luk overlay"},on:{"click":_vm.closeLightbox}},[_c('SvgClose',{staticClass:"w-12 h-12 m-10",attrs:{"aria-hidden":"true"}})],1)],1):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
	<div
		:id="`gallery-${_uid}`"
		class="c-block-list-image-gallery-custom w-5col >=768:w-8col mx-auto"
	>
		<LongReadTarget
			v-if="title"
			:container-id="`gallery-${_uid}`"
			:title="title"
		>
			<BaseH2 class="text-primary-button mb-sm" v-text="title" />
		</LongReadTarget>

		<!-- Gallery Content -->
		<div
			v-if="previewImages && previewImages.length"
			:class="[
				'block space-y-18',
				'>=768:grid >=768:grid-cols-2',
				'>=768:space-y-0 >=768:gap-x-32',
			]"
		>
			<div
				v-for="(column, i) in previewImages"
				:key="`column-${i}`"
				class="space-y-18 >=768:space-y-32"
			>
				<div
					v-for="(image, j) in column"
					:key="`image-${j}-${image.id}`"
					:class="[
						'c-block-list-image-gallery-custom__item',
						'relative group w-full',
					]"
				>
					<button
						class="w-full h-full overflow-hidden rounded-md"
						aria-label="Se billede i fuld-skærm"
						@click="() => openLightbox(images.indexOf(image))"
					>
						<UmbracoImageExt
							:alt="image.altText"
							:widths="[320, 417, 768, 417 * 2, 768 * 2]"
							:source-url="image.cropUrl || image.url"
							:source-width="image.width"
							:source-height="image.height"
							image-class-names="w-full"
							class="w-full h-full"
						/>

						<BaseButton
							tag="div"
							:class="[
								'c-block-list-image-gallery-custom__fullscreen',
								'absolute top-8 right-8',
							]"
						>
							<template #icon>
								<SvgFullscreen />
							</template>
						</BaseButton>
					</button>

					<!-- Credit -->
					<div
						v-if="image.imageCredits"
						:class="[
							'c-block-list-image-gallery-custom__credit',
							'absolute bottom-14 right-8 text-white text-16 rounded-full flex items-center justify-center py-4 px-16',
						]"
					>
						<SvgCopyright class="w-14 h-14 mr-6" />
						<div class="w-fit whitespace-nowrap">
							{{ image.imageCredits }}
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- See all images -->
		<BaseButton
			v-if="images && images.length"
			:class="[
				'c-block-list-image-gallery-custom__see-all',
				'mx-auto uppercase mt-md',
			]"
			@click="openLightbox(0)"
		>
			<template #default>{{ titles.seeAllTitle }}</template>
			<template #icon><SvgFullscreen /></template>
		</BaseButton>

		<!-- Light box (Overlay) -->
		<!--
			Note: all classes have the prefix 'c-' removed,
			since all elements with classes that start with
			'c-block-list-', that isn't within another, is
			given the block margin, and since this is in
			the overlay, it isn't within, well, another.
		-->
		<Portal to="overlay">
			<Transition
				:duration="2000"
				name="t-block-list-images-gallery-custom__lightbox"
			>
				<div
					v-if="overlayActive"
					:class="[
						'block-list-image-gallery-custom__lightbox',
						'relative bg-white',
					]"
				>
					<TransitionExt
						name="block-list-images-gallery-custom__lightbox-item"
						:duration="700"
						mode="out-in"
					>
						<figure
							:key="`lightbox-item-${index}`"
							ref="lightboxContainer"
							:class="[
								'block-list-image-gallery-custom__lightbox-item',
								'relative flex flex-shrink-0 justify-center items-center',
								'w-full h-screen py-xl px-layout-margin snap-center',
							]"
						>
							<div
								:style="{
									width: `${renderedImages[index].renderedWidth}px`,
									height: `${renderedImages[index].renderedHeight}px`,
								}"
								class="relative"
							>
								<!-- Image -->
								<UmbracoImageExt
									:aria-describedby="`lightbox-description-${index}`"
									:alt="images[index].altText"
									:widths="[
										320,
										480,
										768,
										1024,
										1440,
										1920,
										1024 * 2,
										1440 * 2,
										1920 * 2,
									]"
									:source-width="images[index].width"
									:source-height="images[index].height"
									:source-url="
										images[index].cropUrl ||
										images[index].url
									"
									:style="{
										width: `${renderedImages[index].renderedWidth}px`,
										height: `${renderedImages[index].renderedHeight}px`,
									}"
									:class="[
										'relative z-10',
										'overflow-hidden rounded-md',
									]"
								/>

								<!-- Credit -->
								<div
									v-if="images[index].imageCredits"
									:class="[
										'c-block-list-image-gallery-custom__credit',
										'absolute bottom-8 right-8 z-10 text-white text-16 rounded-full flex items-center py-4 px-16 m-0',
									]"
								>
									<SvgCopyright class="w-14 h-14 mr-6" />
									<div class="w-fit whitespace-nowrap">
										{{ images[index].imageCredits }}
									</div>
								</div>
							</div>

							<!-- Image Text -->
							<div
								:class="[
									'block-list-image-gallery-custom__lightbox-text',
									'absolute text-center text-body h-xl w-full',
									'flex flex-col items-center justify-center',
								]"
							>
								<div
									:class="[
										'text-primary-button',
										'font-semibold',
									]"
									v-text="
										`${index + 1} / ${
											images && images.length
										}`
									"
								></div>

								<figcaption
									v-if="images[index].imageText"
									:id="`lightbox-description-${index}`"
									class="mt-8"
									v-html="images[index].imageText"
								></figcaption>
							</div>
						</figure>
					</TransitionExt>

					<!-- Previous Button -->
					<BaseButton
						:aria-disabled="index === 0"
						aria-label="Se forrige billede"
						:class="[
							'block-list-image-gallery-custom__lightbox-button',
							'absolute top-1/2 left-lg z-10',
							'transform -translate-x-1/2 -translate-y-1/2',
						]"
						@click="gotoPrevious"
					>
						<template #icon>
							<SvgCaret class="transform -scale-x-100" />
						</template>
					</BaseButton>

					<!-- Next Button -->
					<BaseButton
						:aria-disabled="
							images ? index === images.length - 1 : true
						"
						aria-label="Se næste billede"
						:class="[
							'block-list-image-gallery-custom__lightbox-button',
							'absolute top-1/2 right-lg z-10',
							'transform translate-x-1/2 -translate-y-1/2',
						]"
						@click="gotoNext"
					>
						<template #icon>
							<SvgCaret />
						</template>
					</BaseButton>

					<!-- Close Button -->
					<button
						aria-label="Luk overlay"
						:class="[
							'absolute top-md right-md',
							'transform -translate-y-1/2 translate-x-1/2',
							'duration-200 ease-wipe-out',
							'opacity-70 hover:opacity-100',
						]"
						@click="closeLightbox"
					>
						<SvgClose aria-hidden="true" class="w-12 h-12 m-10" />
					</button>
				</div>
			</Transition>
		</Portal>
	</div>
</template>

<script>
import UmbracoImageExt from '~/components/shared/UmbracoImageExt';
import LongReadTarget from '~/citi-baseline/components/LongReadTarget';
import SvgFullscreen from '~/assets/svgs/fullscreen-icon.svg?inline';
import SvgClose from '~/assets/svgs/close-icon.svg?inline';
import SvgCaret from '~/assets/svgs/caret-icon.svg?inline';
import SvgCopyright from '~/assets/svgs/copyright-icon.svg?inline';

export default {
	name: 'BlockListImageGalleryCustom',

	components: {
		UmbracoImageExt,
		LongReadTarget,
		SvgFullscreen,
		SvgClose,
		SvgCaret,
		SvgCopyright,
	},

	inheritAttrs: false,

	props: {
		title: {
			type: String,
			required: false,
		},
		titles: {
			type: Object,
			required: false,
		},
		images: {
			type: Array,
			default: () => [],
		},
	},

	data() {
		return {
			overlayActive: false,
			index: 0,

			dimensions: {
				width: 0,
				height: 0,
			},
		};
	},

	computed: {
		previewImages() {
			return [this.images.slice(0, 3), this.images.slice(3, 5)];
		},
		renderedImages() {
			return this.images.map((image) => {
				const { width, height } = this.dimensions;
				let renderedWidth = width;
				let renderedHeight =
					(image.height / image.width) * renderedWidth;

				if (renderedHeight > height) {
					renderedHeight = height;
					renderedWidth =
						(image.width / image.height) * renderedHeight;
				}

				return {
					...image,
					renderedWidth,
					renderedHeight,
				};
			});
		},
	},

	mounted() {
		this.onResize();
		window.addEventListener('resize', this.onResize);
	},

	beforeDestroy() {
		window.removeEventListener('resize', this.onResize);
	},

	methods: {
		openLightbox(index) {
			this.overlayActive = true;
			this.index = Number(index);

			this.$nextTick(() => {
				this.$nextTick(() => {
					this.onResize();
				});
			});
		},
		closeLightbox() {
			this.overlayActive = false;
		},
		gotoPrevious() {
			this.index !== 0 && this.index--;
		},
		gotoNext() {
			this.index !== this.images?.length - 1 && this.index++;
		},
		onResize() {
			const { innerWidth } = window;
			let insetX = 128;
			let insetY = 128;

			if (this.$refs?.lightboxContainer) {
				const { lightboxContainer } = this.$refs;
				const { paddingLeft, paddingTop } =
					getComputedStyle(lightboxContainer);

				insetX = parseFloat(paddingLeft);
				insetY = parseFloat(paddingTop);
			}

			this.dimensions = {
				width:
					window.innerWidth - (innerWidth >= 768 ? insetX : 16) * 2,
				height: window.innerHeight - insetY * 2,
			};
		},
	},
};
</script>

<style>
.block-list-image-gallery-custom__lightbox-text {
	@apply px-layout-margin bottom-0 left-0;
}
.c-block-list-image-gallery-custom__credit {
	background-color: rgba(0, 0, 0, 0.7) !important;
}

@screen >=768 {
	.block-list-image-gallery-custom__lightbox-text {
		@apply top-auto bottom-0 left-0 w-full;

		padding-right: calc(var(--theme-spacing-lg) + 80px) !important;
		padding-left: calc(var(--theme-spacing-lg) + 80px) !important;
	}

	.block-list-image-gallery-custom__lightbox-item {
		padding-right: calc(var(--theme-spacing-lg) + 80px) !important;
		padding-left: calc(var(--theme-spacing-lg) + 80px) !important;
	}
}

.c-block-list-image-gallery-custom__item .c-umbraco-image {
	@apply overflow-hidden;
}

.c-block-list-image-gallery-custom__item:hover .c-umbraco-image__image {
	transform: scale(1.1);
}

.t-block-list-images-gallery-custom__lightbox-enter-active,
.t-block-list-images-gallery-custom__lightbox-leave-active {
	@apply transform;

	transition: 700ms cubic-bezier(0.5, 0.035, 0.19, 1) !important;
}

.t-block-list-images-gallery-custom__lightbox-enter-active > *,
.t-block-list-images-gallery-custom__lightbox-leave-active > * {
	@apply delay-700 duration-700 ease-smooth-out transform;
}

.t-block-list-images-gallery-custom__lightbox-enter-active
	> .block-list-image-gallery-custom__lightbox-button,
.t-block-list-images-gallery-custom__lightbox-leave-active
	> .block-list-image-gallery-custom__lightbox-button {
	@apply delay-1000 duration-1000 ease-smooth-out transform origin-center;
}

.t-block-list-images-gallery-custom__lightbox-enter,
.t-block-list-images-gallery-custom__lightbox-leave-to {
	@apply opacity-0;
}

.t-block-list-images-gallery-custom__lightbox-enter > *,
.t-block-list-images-gallery-custom__lightbox-leave-to > * {
	transform: scale(1.1);
	opacity: 0;
}

.t-block-list-images-gallery-custom__lightbox-enter
	> .block-list-image-gallery-custom__lightbox-button,
.t-block-list-images-gallery-custom__lightbox-leave-to
	> .block-list-image-gallery-custom__lightbox-button {
	opacity: 0;
}

.t-block-list-images-gallery-custom__lightbox-enter > *,
.t-block-list-images-gallery-custom__lightbox-leave-to > * {
	@apply scale-85;
}

.t-block-list-images-gallery-custom__lightbox-item-enter-active,
.t-block-list-images-gallery-custom__lightbox-item-leave-active {
	@apply ease-smooth-out;
}

.t-block-list-images-gallery-custom__lightbox-item-enter,
.t-block-list-images-gallery-custom__lightbox-item-leave-to {
	@apply opacity-0;

	transform: scale(0.98);
}
</style>
